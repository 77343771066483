import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    yellow: '#faf067',
    darkYellow: '#f7c036',
    orange: '#e0713f',
    red: '#c4323d',
    green: '#5cbc4d',
    primary: {
      light: '#2697b5',
      main: '#015581',
    },
    secondary: {
      main: '#f5bf39',
    },
  },
  typography: {
    fontFamily: 'arial, sans-serif',
    fontSize: 16,
    useNextVariants: true,
  },
});

export default theme;
export const { palette, typography } = theme;
