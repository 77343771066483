import React, { useRef, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useWindowSize } from 'react-use';
import max from 'lodash/max';
import min from 'lodash/min';

import Hex from './Hex';

const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin: 8px;
`;

const RowWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -${p => (p.intendTop ? p.hexWidth / Math.sqrt(3) / 2 : '0')}px;
`;

const Row = ({ hexWidth, rowStyle, row, ...rest }) => (
  <RowWrap
    hexWidth={hexWidth}
    {...rest}
  >
    {row.map((hex, key) => <Hex key={key} {...hex} width={hexWidth} />)}
  </RowWrap>
);

export default ({ grid, ...rest }) => {
  const gridRef = useRef(null);
  const [hexWidth, setHexWidth] = useState(0);
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  useEffect(() => {
    const { width, height } = gridRef.current.getBoundingClientRect();
    const maxHorizontalWidth = width / max(grid.map(r => r.length));
    const maxVerticalWidth = height / grid.length;
    setHexWidth(min([maxHorizontalWidth, maxVerticalWidth]));
  }, [windowWidth, windowHeight]);

  return (
    <Grid ref={gridRef} {...rest}>
      {hexWidth && grid.map((row, index) => (
        <Row
          row={row}
          intendTop={index !== 0}
          hexWidth={hexWidth}
          key={index}
        />
      ))}
    </Grid>
  );
};
