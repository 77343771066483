import React, { useState, useRef, useEffect } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import styled from '@emotion/styled';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';

// import { palette } from '@theme';

import ImpressumContent from './ImpressumContent';

const Wrap = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .video {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 177.77777778vh; /* 100 * 16 / 9 */
    min-width: 100%;
    min-height: 56.25vw; /* 100 * 9 / 16 */

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      top:0px;
      left:0px;
    }
  }

  @keyframes animate-heading {
    from {
      opacity: 0.6;
      transform: scale(0.7);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes animate-link {
    from {
      opacity: 0.6;
      transform: translateX(-30px);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }
`;

const Box = styled.div`
  max-width: 380px;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 2px;
  padding: 5px 5px;
  margin: 5px;
  position: relative;
  z-index: 2;
  &.broad {
    max-width: 750px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  button {
    background: transparent;
    border: none;
    font-size: 1em;
    outline: none;
  }

  h2 {
    min-width: 300px;
    transform: scale(0.7);
    font-size: 2.5em;
    line-height: 1.5em;
    color: #fff;
    background: #0073a0;
    padding: 8px 12px;
    margin: 0px;
    opacity: 0.6;
    position: relative;
    z-index: 1;

    &.animate-heading {
      animation: animate-heading 4s ease-out;
      animation-fill-mode: forwards;
    }
  }
  h3 {
    transform: scale(0.7);
    font-size: 2em;
    line-height: 1.2em;
    color: #fff;
    background: #0073a0;
    padding: 8px 12px;
    margin: 0px;
    opacity: 0.6;
    position: relative;
    z-index: 1;
    display: block;

    &.animate-heading {
      animation: animate-heading 4s ease-out;
      animation-fill-mode: forwards;
      animation-delay: 2100ms;
    }
  }

  button {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    /* margin-left: -16px; */
    color: #fff;
    background: #0073a0;
    padding: 10px 12px;
    border-bottom: 1px solid;
    opacity: 0;
    position: relative;

    a {
      text-decoration: none;
      color: #fff; 
    }

    &:last-child {
      border-bottom: none;
      border-right: none;
    }

    &.animate-link {
      animation: animate-link 500ms ease-out;
      animation-fill-mode: forwards;
      animation-delay: 4200ms;
    }

    &.inline {
      display: inline;
      width: 174px;
      border-right: 1px solid;
      border-bottom: none;
    }
  }

  & button:nth-child(2) {
    animation-delay: 4000ms;
  }
`;

const CustomModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .body {
    width: 100%;
    max-width: 600px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0 , 0.6);
    border-radius: 4px;
    position: relative;
    outline: none;
  }

  .close-button {
    position: absolute;
    top: 6px;
    right: 6px;
    z-index: 1;
  }

  .content {
    padding: 40px;
  }
`;

export default ({ active }) => {
  const { t } = useTranslation('contact');
  const [impressumOpen, setImpressumOpen] = useState(false);
  const [animate, setAnimate] = useState(false);
  const video = useRef(null);

  useEffect(() => {
    if (!active) return;
    setTimeout(() => video.current.play(), 300);
    setAnimate(true);
  }, [active]);
  return (
    <Wrap>
      <CustomModal
        open={impressumOpen}
        onClose={() => setImpressumOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={impressumOpen}>
          <div className="body">
            <IconButton
              className="close-button"
              aria-label="close"
              onClick={() => setImpressumOpen(false)}
            >
              <ClearIcon />
            </IconButton>
            <div className="content">
              <ImpressumContent />
            </div>
          </div>
        </Fade>
      </CustomModal>

      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video ref={video} className="video" src={require('@assets/video/water.mp4').default} autoPlay loop muted />
      <Box className="broad" style={{ marginBottom: 0, paddingBottom: 0 }}>
        <h2 className={animate ? 'animate-heading' : ''}>{t('startsNow')}</h2>
      </Box>
      <Box className="broad" style={{ marginTop: 0, paddingTop: 0 }}>
        <button type="button" className={animate ? 'animate-link' : ''}>
          <ChevronRightIcon />
          <a target="_blank" href="mailto:Andreas.Holzhammer@AquaResource.eu" rel="noreferrer">Andreas.Holzhammer@AquaResource.eu</a>
        </button>
        <button type="button" className={animate ? 'animate-link' : ''}>
          <ChevronRightIcon />
          {t('news')}
          <a href="https://www.linkedin.com/company/aquaresource" target="_blank" rel="noreferrer">
            <img alt="LinkedInLogo" style={{ width: '100px', marginLeft: '3px', padding: '2px', backgroundColor: 'white' }} src={require('@assets/img/LI-Logo.svg.original.svg').default} />
          </a>
        </button>
        <button className={animate ? 'animate-link' : ''} type="button" onClick={() => setImpressumOpen(true)}>
          <ChevronRightIcon />
          <span>{t('imprint')}</span>
        </button>
      </Box>
      <Box className="broad" style={{ marginBottom: 0, paddingBottom: 0 }}>
        <h3 className={animate ? 'animate-heading' : ''}>
          {t('partners')}
        </h3>
      </Box>
      <Box className="broad" style={{ marginTop: 0, paddingTop: 0 }}>
        <button type="button" className={animate ? 'inline animate-link' : 'inline'}>
          <a target="_blank" rel="noreferrer" href="https://www.richterecos.com/de/"><img alt="Richter ECOS" width="150px" style={{ backgroundColor: 'white', padding: '2px' }} src={require('@assets/img/richter_ecos_logo.png').default} /> Richter ECOS GmbH</a>
        </button>
        <button type="button" className={animate ? 'inline animate-link' : 'inline'}>
          <a target="_blank" rel="noreferrer" href="https://greentech.earth"><img alt="Greentech Alliance" width="150px" src={require('@assets/img/GreentechAllianceMemberAquaResource_final.jpg').default} /> The&nbsp;Greentech Alliance</a>
        </button>
        <button type="button" className={animate ? 'inline animate-link' : 'inline'}>
          <a target="_blank" rel="noreferrer" href="https://www.leiblein.de/"><img alt="Leiblein" width="150px" src={require('@assets/img/leiblein.jpg').default} /> Leiblein GmbH</a>
        </button>
      </Box>
    </Wrap>
  );
};
